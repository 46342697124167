<template>
  <el-dialog :title="title" :visible.sync="show" width="50%">
    <el-form :model="form" label-width="120px">
      <el-form-item label="友情链接名称">
        <el-input v-model="form.linkName" />
      </el-form-item>
      <el-form-item label="链接地址">
        <el-input v-model="form.linkUrl" />
      </el-form-item>
      <el-form-item label="是否nofollow">
        <el-radio-group v-model="form.isNofollow">
          <el-radio
            v-for="item in isNofollowList"
            :key="item.id"
            :label="item.id"
          >
            {{ item.value }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="显示">
        <el-radio-group v-model="form.isHide">
          <el-radio v-for="item in isHideList" :key="item.id" :label="item.id">
            {{ item.value }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="add">{{
        btn ? '确 定' : 'loading..'
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  inject: ['getlist'],
  data() {
    return {
      show: false,
      form: {
        isHide: 0,
        isNofollow: 1,
        linkName: '',
        linkUrl: ''
      },
      btn: true,
      isNofollowList: [
        { id: 0, value: '没有nofollow' },
        { id: 1, value: '有nofollow' }
      ],
      isHideList: [
        { id: 0, value: '直接显示' },
        { id: 1, value: '隐藏显示' }
      ]
    }
  },
  methods: {
    async add() {
      if (!this.btn) return
      this.btn = false
      var url = this.form.id
        ? '/admin/FriendLink/edit'
        : '/admin/FriendLink/add'
      var { data: res } = await this.$http.post(url, this.form)
      if (res.errorCode == 200) {
        this.getlist()
        this.$message.success(res.message)
        this.show = false
      }
      this.btn = true
    },
    toAdd() {
      this.show = true
      this.form = this.$options.data.call(this).form
    }
  },
  computed: {
    title() {
     return this.form.id ? '修改友情链接' : '新增友情链接'
    }
  }
}
</script>

<style lang="less" scoped></style>
