<template>
  <div>
    <el-button
      v-show="$store.state.btn_if.indexOf('friend_link_add') != -1"
      type="primary"
      @click="$refs.friendshipLink.toAdd()"
      >新增友情链接</el-button
    >
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号" />
        <el-table-column prop="linkName" label="友情链接名字" width="180" />
        <el-table-column prop="linkUrl" label="链接地址">
          <template slot-scope="ew">
            <a :href="ew.row.linkUrl" target="“_blank”">{{ ew.row.linkUrl }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="hits" label="点击量" />
        <el-table-column prop="createTime" label="时间" sortable="custom" />
        <el-table-column prop="orderNum" label="排序" sortable="custom">
          <template #header>
            <span>排序</span>
            <div style="font-size:12px">（当前最大值：{{ orderMax }}）</div>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                :ref="'INPUTFOCUS' + $index"
                v-model="row.orderNum"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row)"
              />
            </div>
            <span
              v-show="!row.pai"
              class="el-icon-edit"
              @click="
                $store.state.btn_if.indexOf('friend_link_order') != -1 &&
                  showinput(row, 'INPUTFOCUS' + $index)
              "
            >
              {{ row.orderNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-show="$store.state.btn_if.indexOf('friend_link_edit') != -1"
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              @click="edit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-show="$store.state.btn_if.indexOf('friend_link_delete') != -1"
              type="text"
              icon="el-icon-delete"
              size="mini"
              class="red"
              @click="open(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <modify-the-friendshipLink ref="friendshipLink"></modify-the-friendshipLink>
  </div>
</template>

<script>
import $ from 'jquery'
import modifyTheFriendshipLink from './system_frend_link/modifyTheFriendshipLink'
export default {
  provide() {
    return {
      getlist: this.getlist
    }
  },
  data() {
    return {
      btn: true,
      currentPage: 1,
      pagesize: 10,
      list: [],
      form: {
        id: '',
        linkName: '',
        linkUrl: ''
      },
      form_1: {
        linkName: '',
        linkUrl: ''
      },
      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false,
      paiform: {},
      orderMax: 0
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    rowClass() {
      return 'background:#F5F7FA;'
    },
    async handleInputBlur(row) {
      this.paiform = row
      try {
        const { data: res } = await this.$http.post(
          '/admin/FriendLink/edit',
          this.paiform
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          //  row.pai=false
          //  this.tableData=[...this.tableData]
          this.getlist()
        }
      } catch (e) {
        // this.$message.error(e)
      }
      console.log(this.tableData)
    },
    getlist() {
      this.$http
        .get('/admin/FriendLink/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
            if (res.data.orderMax) {
              this.orderMax = res.data.orderMax
            }
          }
        })
    },
    showinput(row, refName) {
      console.log(row, refName, this.$refs[refName])
      row.pai = true
      this.list = [...this.list]
      setTimeout(() => {
        $('.id input').focus()
      }, 0)
    },
    edit(e) {
      this.dialogVisible = true
      this.$http
        .get('/admin/FriendLink/getById?id=' + e)
        .then(({ data: res }) => {
          this.$refs.friendshipLink.form = res.data
          this.$refs.friendshipLink.show = true
        })
    },

    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/FriendLink/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'fl_order_num'
        } else {
          s = 'fl_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },

  },
  components: {
    modifyTheFriendshipLink
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.skyblue {
  color: skyblue;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
